import { ActivatedRoute, DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

    caseUrl: any;
    parse(url: string): UrlTree {

        if (url.includes('=')) {
            const addSeparator = url.replace(/&/gi, ",&");
            const values = addSeparator.split('=');
            this.caseUrl = '';
            values.forEach(e => {
                const data = e.split(',');
                data.forEach(ele => {
                    if (ele.includes('&') || ele.includes('?')) {
                        this.caseUrl = this.caseUrl + ele.toLowerCase() + '=';
                    }
                    else {
                        this.caseUrl = this.caseUrl + ele;
                    }
                })
            })
        }
        else {

            this.caseUrl = url.toLowerCase();
        }

        return super.parse(this.caseUrl);

    }
}