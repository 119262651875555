/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tooltip.component";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
function View_TooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container toolltipWrapper"], ["id", "toolltipWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipComponent_1)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.content; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 49152, null, 0, i3.TooltipComponent, [], null, null)], null, null); }
var TooltipComponentNgFactory = i1.ɵccf("app-tooltip", i3.TooltipComponent, View_TooltipComponent_Host_0, { text: "text", content: "content" }, {}, []);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
