import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
//components
import { AppComponent } from './app.component';
import { SharedModule } from './shared/_modules/shared/shared.module';

//interceptor
import { httpInterceptorProviders } from "./shared/_interceptor";
import { APIInterceptor } from "./shared/_interceptor/API.interceptor";

//Services
import { AuthenticationService } from "./shared/_service/authentication.service"
import { AppDataService } from "./shared/_service/appdata.service"
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './shared/_providers/urlserializer';

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,

  ],
  providers: [
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    AuthenticationService,
    AppDataService,
    httpInterceptorProviders, { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
