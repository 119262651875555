import { Routes } from '@angular/router';
var ɵ0 = function () {
    return import("./_modules/mainscreen/mainscreen.module.ngfactory").then(function (m) { return m.MainscreenModuleNgFactory; });
}, ɵ1 = function () {
    return import("./_modules/error/error.module.ngfactory").then(function (m) { return m.ErrorModuleNgFactory; });
}, ɵ2 = function () {
    return import("./_modules/not-found/not-found.module.ngfactory").then(function (m) { return m.NotFoundModuleNgFactory; });
};
var routes = [
    {
        path: "",
        loadChildren: ɵ0,
    },
    {
        path: 'error',
        loadChildren: ɵ1,
    },
    {
        path: '**',
        loadChildren: ɵ2,
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
