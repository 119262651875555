import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  {
    path: "",
    loadChildren: () =>
      import("./_modules/mainscreen/mainscreen.module").then(
        (m) => m.MainscreenModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
       import("./_modules/error/error.module").then(
        (m) => m.ErrorModule
       ),
  },
  {
    path: '**',
    loadChildren: () =>
      import("./_modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
