import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

//environment
import { environment } from "../../../environments/environment";
// Interface
import { AuthToken } from "../_interface/authToken.interface";
import { ValidateAuthRequest } from 'src/app/_modules/error/_interface/validate.inteface';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  tokenData: AuthToken = null;

  constructor(private http: HttpClient) {
    let that = this;
    let tokenInterval = setInterval(function () {
      if (that.tokenData && that.tokenData.expires_in) {
        that.tokenData.expires_in -= 1;
        if (that.tokenData.expires_in == 0)
          clearInterval(tokenInterval)
      }
    }, 60000);

  }

  public setAuthToken(data: AuthToken) {
    this.tokenData = data;
  }

  public getAuthToken() {
    return this.tokenData;
  }

  public validateAuth(data): Observable<any> {
    let payload: ValidateAuthRequest = { authenticationkey: data.authenticationkey, timestamp: data.timestamp }
    let url = environment.API.Validate.url + `/uniqueid/${data.uniqueid}/agentextension/${data.agentextension}/validate`;
    return this.http.post(url, payload);
  };

}


