import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var AppDataService = /** @class */ (function () {
    function AppDataService() {
        this.appData = {
            uniqueID: '',
            agentExtension: '',
            authKey: ''
        };
        this.loader$ = new Subject();
    }
    AppDataService.prototype.setAppData = function (data) {
        this.appData = data;
    };
    AppDataService.prototype.getAppData = function () {
        return this.appData;
    };
    AppDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppDataService_Factory() { return new AppDataService(); }, token: AppDataService, providedIn: "root" });
    return AppDataService;
}());
export { AppDataService };
