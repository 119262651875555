import { Component } from '@angular/core';
//service
import { AppDataService } from "./shared/_service/appdata.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title: string = 'e911-app';
  loader: boolean = true;

  constructor(private appDataService: AppDataService) {
    this.appDataService.loader$.subscribe(loader => {
      this.loader = loader;
    });
  }

}
