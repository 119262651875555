import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from '../../_components/tooltip/tooltip.component'
import { TooltipDirective } from '../../_directive/tooltip.directive';
import  { HeaderComponent} from '../../_components/header/header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    TooltipComponent,
    TooltipDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    TooltipComponent,
    TooltipDirective
  ],
  entryComponents : [TooltipComponent]
})

export class SharedModule { }
