// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleMapKey:  "AIzaSyDM9demAE81wB88r3DkBwZfxW-q1r7loLc",
  API : {
    Notes : {
      url : "https://agero-qa.apigee.net/e911-app/qa/notes",
    },
    Log: {
      url: "https://agero-qa.apigee.net/e911-app/qa/externallog",
    },
    Validate: {
      url: "https://agero-qa.apigee.net/e911-app/qa/authenticate",
    },
    dial: {
      url: "https://agero-qa.apigee.net/e911-app/qa/psap",
    },
    endcall: {
      url: "https://agero-qa.apigee.net/e911-app/qa/ende911call",
    },
    callstatus : {
      url : "https://agero-qa.apigee.net/e911-app/qa/callstatus"
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
