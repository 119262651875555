import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Interface
import { AppData } from "../_interface/authToken.interface";

@Injectable({
  providedIn: 'root'
})

export class AppDataService {

  appData: AppData = {
    uniqueID: '',
    agentExtension: '',
    authKey: ''
  }
  public loader$ = new Subject<boolean>();

  public setAppData(data: AppData) {
    this.appData = data;
  }

  public getAppData() {
    return this.appData;
  }
}


