import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from "../../shared/_service/authentication.service"
import { Observable} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()

export class APIInterceptor implements HttpInterceptor {

    constructor(public authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let correlation_id = uuidv4();
        request = request.clone({
            setHeaders: {
                "x-correlation-id": `${correlation_id}`
            }
        });

        if (request.url.indexOf("/validate") == -1
            && request.url.indexOf("/psap") == -1
            && request.url.indexOf("/ende911call") == -1
            && request.url.indexOf("/externallog") == -1) {
            let token = this.authService.getAuthToken();
            let auth_key = token ? token.auth_key : '';
            request = request.clone({
                setHeaders: {
                    "Accept": "application/json",
                    "Content-type":"application/json",
                    "agero-psap-auth-key": `${auth_key}`
                }
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    "Accept": "application/json",
                    "Content-type":"application/json",
                }
            });
        }
        return next.handle(request);
    }
}