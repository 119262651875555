import * as tslib_1 from "tslib";
import { DefaultUrlSerializer } from '@angular/router';
var LowerCaseUrlSerializer = /** @class */ (function (_super) {
    tslib_1.__extends(LowerCaseUrlSerializer, _super);
    function LowerCaseUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LowerCaseUrlSerializer.prototype.parse = function (url) {
        var _this = this;
        if (url.includes('=')) {
            var addSeparator = url.replace(/&/gi, ",&");
            var values = addSeparator.split('=');
            this.caseUrl = '';
            values.forEach(function (e) {
                var data = e.split(',');
                data.forEach(function (ele) {
                    if (ele.includes('&') || ele.includes('?')) {
                        _this.caseUrl = _this.caseUrl + ele.toLowerCase() + '=';
                    }
                    else {
                        _this.caseUrl = _this.caseUrl + ele;
                    }
                });
            });
        }
        else {
            this.caseUrl = url.toLowerCase();
        }
        return _super.prototype.parse.call(this, this.caseUrl);
    };
    return LowerCaseUrlSerializer;
}(DefaultUrlSerializer));
export { LowerCaseUrlSerializer };
